import React, { useEffect } from 'react'
import { HcpPage, SEO } from "components";
import { scrollToElement } from 'libs/util.helper';
import { useWindowSize } from 'hooks';
import './sitemap.scss';

const pageDescription = "Learn about AVEED® (testosterone undecanoate) injection CIII, including Safety and Boxed Warning, benefits and risks, and full Prescribing Information. See Safety & PI, including Boxed Warning.";

const SitemapPage = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768;
  useEffect(() => {
    //Fix for showing double ISI in small pages
    setTimeout(() => {
      scrollToElement('hcp-sitemap-page-top', isMobile ? 75 : 120)
    }, 100)
  }, [])
  return (
    <HcpPage pageClassName="sitemap">
      <SEO pageTitle="Site Map" pageDescription={pageDescription} />
      <div className="dfa-container gutter-left gutter-right">
        <h1 id="hcp-sitemap-page-top">Site Map</h1>
        <ul className="link-list">
          <li><a href="/hcp/">HCP Home</a></li>
          <li className='sm-pd-btm'><a href="/hcp/clinical-data/">Clinical Data</a></li>
        </ul>
        <ul className="sub-link-list">
          <li><a href="/hcp/clinical-data/?scrollto=elevate_and_control">Elevate &amp; Control</a></li>
          <li><a href="/hcp/clinical-data/?scrollto=efficacy">Efficacy</a></li>
          <li><a href="/hcp/clinical-data/?scrollto=testosterone_levels">Testosterone Levels</a></li>
        </ul>
        <ul className="link-list">
          <li><a href="/hcp/clinical-safety-profile/">Clinical Safety Profile</a></li>
        </ul>
        <ul className="link-list">
          <li><a href="/hcp/hypogonadism-provider-enrollment/">AVEED<sup>&reg;</sup> REMS</a></li>
        </ul>
        <ul className="link-list">
          <li className='sm-pd-btm'><a href="/hcp/get-aveed/">Get AVEED<sup>&reg;</sup></a></li>
        </ul>
        <ul className="sub-link-list">
          <li><a href="/hcp/get-aveed/?scrollto=access_and_reimbursement">Access &amp; Reimbursement</a></li>
          <li><a href="/hcp/get-aveed/?scrollto=order_via_specialty_pharmacy">Order via Specialty Pharmacy</a></li>
          <li><a href="/hcp/get-aveed/?scrollto=order_via_buy_and_bill">Order via Buy-and-Bill</a></li>
          <li><a href="/hcp/get-aveed/?scrollto=billing_codes">Billing Codes</a></li>
        </ul>
        <ul className="link-list">
          <li><a href="/hcp/dosing-and-administration/">Dosing &amp; Administration</a></li>
          <li><a href="/hcp/paying-for-aveed/">Paying for AVEED<sup>&reg;</sup></a></li>
          <li><a href="/hcp/resources/">Resources</a></li>
        </ul>
      </div>
    </HcpPage>
  )
}

export default SitemapPage
